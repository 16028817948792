html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}

@font-face {
    font-family: 'GmarketSansMedium';
    src: url("./assets/GmarketSansOTF/GmarketSansMedium.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/Noto_Sans_KR/NotoSansKR-Light.otf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/Noto_Sans_KR/NotoSansKR-Regular.otf"); 
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/Noto_Sans_KR/NotoSansKR-Medium.otf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/Noto_Sans_KR/NotoSansKR-Bold.otf"); 
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: lighter;
  src: url("./assets/Noto_Sans_KR/NotoSansKR-Thin.otf");
}

:root {
    font-family: 'Noto Sans KR';
    font-family: 'GmarketSansMedium';
}

body {
    font-family: -apple-system, 'GmarketSansMedium', 'Noto Sans KR', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #000000;
    font-family: 'Noto Sans KR';
    font-weight: 400;
    color: #ffffff;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 12px;
}
body::-webkit-scrollbar-thumb {
    background: #ddd;
    height: 2.5em;
    border-radius: 16px;
} /* 실질적 스크롤 바 */

body::-webkit-scrollbar-thumb:hover {
    background: #1b214f;
} /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */

body::-webkit-scrollbar-thumb:active {
    background: #354cda;
} /* 실질적 스크롤 바를 클릭할 때 */

body::-webkit-scrollbar-button {
    display: none;
}
.root {
    width: 100%;
    height: 100%;
}

html {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 10px;
}

@media (min-width: 1200px) and (max-width: 1920px) {
    html {
        font-size: 9px;
    }
}
@media (min-width: 950px) and (max-width: 1200px) {
    html {
        font-size: 7px;
    }
}

@media (max-width: 950px) {
    html {
        font-size: 5px;
    }
}
